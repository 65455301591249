import React from "react";

interface DynamicButtonProps {
  isLoading?: boolean;
  isDisabled?: boolean;
  onClick?: () => void;
  [key: string]: any;
}

const DynamicButton: React.FC<DynamicButtonProps> = ({
  isLoading,
  isDisabled,
  onClick,
  children,
  ...props
}) => {
  return (
    <button
      type="submit"
      onClick={onClick}
      disabled={isDisabled || isLoading}
      className={`rounded-lg mb-16 self-center text-[17px] border-none text-primary px-6 py-2.5 w-2/3 font-medium flex items-center justify-center ${
        isDisabled || isLoading
          ? "bg-gray-700 cursor-not-allowed"
          : "bg-secondary"
      }`}
      {...props}
    >
      {isLoading ? (
        <>
          <img src="./assets/loader.gif" className="w-10 h-8" alt="" />
        </>
      ) : (
        children
      )}
    </button>
  );
};

export default DynamicButton;
