import StringMask from "string-mask";

export const handleMaskingCNIC = (value: string) => {
  const neatString: string = value?.split("-")?.join("")?.split(" ")?.join("");
  let mask: any = "";
  switch (neatString?.length) {
    case 12: {
      mask = new StringMask("00000 - 0000000", {
        reverse: false,
      });
      break;
    }
    case 5: {
      mask = new StringMask("00000", {
        reverse: false,
      });
      break;
    }
    default: {
      mask = new StringMask("00000 - 0000000 - 0", {
        reverse: false,
      });
      break;
    }
  }
  const masked = mask.apply(neatString);
  return masked;
};

export const cnicFormatter = (cnic: string) =>
  cnic?.split(" ")?.join("").split("-")?.join("");

export const getOS = () => {
  const userAgent = navigator.userAgent || navigator.vendor ;

  // Windows Phone
  if (/windows phone/i.test(userAgent)) {
    return "Windows Phone";
  }

  // Android
  if (/android/i.test(userAgent)) {
    return "Android";
  }

  // iOS
  if (/iPad|iPhone|iPod/.test(userAgent)) {
    return "iOS";
  }

  // Windows
  if (/Win/.test(userAgent)) {
    return "Windows";
  }

  // macOS
  if (/Mac/.test(userAgent)) {
    return "macOS";
  }

  // Linux
  if (/Linux/.test(userAgent)) {
    return "Linux";
  }

  return "Unknown";
};
