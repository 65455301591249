import React, { memo, useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { cnicFormatter, getOS, handleMaskingCNIC } from "../../helper/utils";
import { useDispatch } from "react-redux";
import { AUpdateData } from "../../store/reducer/work-detail";
import { useFetch } from "../../hooks/useFetch";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import _ from "lodash";
import DynamicButton from "../../component/button";
function WorkDetails() {
  // Hooks
  const router = useNavigate();
  const dispatch = useDispatch();
  const [loader, setLoader] = useState<boolean>(false);
  const state = useSelector((state: RootState) => state.invitation.invitation);
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
    setError,
    clearErrors,
  } = useForm({
    reValidateMode: "onChange",
  });
  // Constant
  const cnic = watch("cnic");
  // Functions
  const debouncedSearch = useCallback(
    _.debounce((query) => {
      return checkUserByCnic(query);
    }, 100),
    []
  );

  const sendOTP = () => {
    return useFetch.get(
      `family/send-otp-family?familyInvitationId=${state?.id}`
    );
  };
  const next = async (data: any) => {
    let { cnic, ...rest } = data;
    cnic = cnicFormatter(cnic);
    const payload = {
      cnic,
      ...rest,
    };
    setLoader(true);
    return checkUserByCnic(cnic).then((res) => {
      if (res) setLoader(true);
      return sendOTP()
        .then(() => {
          setLoader(false);
          dispatch(AUpdateData(payload));
          return router("/otp");
        })
        .catch(() => {
          return setLoader(false);
        });
    });
  };
  const checkUserByCnic = (cnic: string) => {
    setLoader(true);
    return useFetch
      .get(`auth/check-user-by-cnic?cnic=${cnic}`)
      .then((res) => {
        setLoader(false);
        if (res?.data?.data?.isUser === false) return clearErrors("cnic");
        setError("cnic", {
          message: "CNIC already registered in our system.",
        });
        return false;
      })
      .catch((err) => {
        setLoader(false);
        if (!err?.response)
          return setError("cnic", {
            message: "Something went wrong. Please try again",
          });
        if (err?.response?.data?.data) {
          if (
            err?.response?.data?.code === 400 &&
            err?.response?.data?.message?.includes("disabled")
          )
            return setError("cnic", {
              message: "User already registered",
            });
          clearErrors("cnic");
          return true;
        }
        if (
          err?.response?.data?.code !== 404 ||
          err?.response?.data?.code !== 400
        )
          return setError("cnic", {
            message: err?.response?.data?.message,
          });

        clearErrors("cnic");
        return true;
      });
  };

  // Effects
  useEffect(() => {
    if (cnic) {
      if (cnicFormatter(cnic)?.length === 13)
        debouncedSearch(cnicFormatter(cnic));
      setValue("cnic", handleMaskingCNIC(cnic));
    }
  }, [cnic, debouncedSearch]);
  useEffect(() => {
    if (!state) router("/invalid");
  }, [state]);
  return (
    <div className="flex flex-col p-5 pt-10 items-start justify-start h-[100vh]">
      <h1 className="text-white text-2xl border-b-2 border-info border-opacity-25 pb-2 pr-6 inline">
        Your Details
      </h1>
      <p className="text-info mt-8">Apni details enter karein</p>
      <form
        onSubmit={handleSubmit(next)}
        className="flex flex-col w-full h-full flex flex-col justify-between"
      >
        <div className="flex flex-col py-6 space-y-8">
          <div className="flex flex-col">
            <label htmlFor="cnic" className="text-white text-lg font-medium">
              Enter CNIC
            </label>
            <input
              type="text"
              id="cnic"
              className="bg-transparent text-info border-b-info border-b-2 pb-2 mt-3 border-opacity-25"
              placeholder="xxxxx-xxxx-xxx-x"
              {...register("cnic", {
                required: {
                  value: true,
                  message: "Please enter CNIC",
                },
                pattern: {
                  value: /^[0-9]{5} - [0-9]{7} - [0-9]$/,
                  message: "Please enter valid CNIC",
                },
              })}
            />
            <p className="text-red-400 text-sm mt-2">
              {errors["cnic"]?.message as any}
            </p>
          </div>
          <div className="flex flex-col">
            <label htmlFor="cnic" className="text-white text-lg font-medium">
              Enter CNIC Issue Date
            </label>
            <input
              type="date"
              id="issueDate"
              className="bg-transparent text-info border-b-info border-b-2 pb-2 mt-3 border-opacity-25"
              {...register("issueDate", {
                required: {
                  value: true,
                  message: "Please enter CNIC Issue Date",
                },
                validate: {
                  notInFuture: (value) => {
                    const today = new Date();
                    const selectedDate = new Date(value);
                    return (
                      selectedDate <= today ||
                      "CNIC Date cannot be in the future"
                    );
                  },
                },
              })}
            />
            <p className="text-red-400 text-sm mt-2">
              {errors["issueDate"]?.message as any}
            </p>
          </div>
          <div className="flex flex-col">
            <label htmlFor="cnic" className="text-white text-lg font-medium">
              Enter Date of Birth
            </label>
            <input
              type="date"
              id="dob"
              className="bg-transparent text-info border-b-info border-b-2 pb-2 mt-3 border-opacity-25"
              placeholder="xxxxx-xxxx-xxx-x"
              {...register("dob", {
                required: {
                  value: true,
                  message: "Please enter date of birth",
                },
                validate: {
                  notInFuture: (value) => {
                    const today = new Date();
                    const selectedDate = new Date(value);
                    return (
                      selectedDate <= today || "DOB cannot be in the future"
                    );
                  },
                },
              })}
            />
            <p className="text-red-400 text-sm mt-2">
              {errors["dob"]?.message as any}
            </p>
          </div>
        </div>
        <DynamicButton
          isDisabled={Object.keys(errors)?.length > 0}
          type="submit"
          isLoading={loader}
        >
          NEXT
        </DynamicButton>
      </form>
    </div>
  );
}

export default memo(WorkDetails);
